<template>
    <ODataLookup :data-object="dsScope_ProcessesLkp">
        <OColumn field="ID" width="100"></OColumn>
        <OColumn field="Name" width="300"></OColumn>
    </ODataLookup>
</template>

<script setup>

const dsScope_ProcessesLkp = $getDataObjectById("dsScope_ProcessesLkp");
</script>